import '../sass/frontend.scss'
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";
import "@fancyapps/ui/dist/fancybox.css";
import SaunaListItem from './components/SaunaListItem';

Fancybox.bind('.lightbox-gallery a', {
    groupAll: true
});

(() => {
	document.querySelectorAll('.sauna-list-block .sauna-list-item').forEach(item => {
		new SaunaListItem(item);
	});
})();




// Find all .sauna-list-item elements by their class
const saunaListItems = document.querySelectorAll('.sauna-list .sauna-list-item');

// Define the new text and link
const newText = 'Contact opnemen';
const newLink = '/contact/';

// Iterate through all .sauna-list-item elements
saunaListItems.forEach((saunaListItem) => {
	// Find the <a> element within each .sauna-list-item
	const linkElement = saunaListItem.querySelector('.sauna-list-item__button a');

	// Change the text
	linkElement.textContent = newText;

	// Change the link (href attribute)
	linkElement.href = newLink;
});

