import { Splide } from "@splidejs/splide";
import '@splidejs/splide/css/core';

class SaunaListItem {
	/**
	 * @param {HTMLElement}
	 */
	#rootElement;

	/**
	 * @param {Splide}
	 */
	#splide;

	/**
	 * @param {HTMLElement[]}
	 */
	#thumbnailElements;

	/**
	 * 
	 * @param {HTMLElement} rootElement The root element
	 */
	constructor(rootElement) {
		this.#rootElement = rootElement;

		const splideElement = this.#rootElement.querySelector('.splide');
		if (!splideElement) {
			return;
		}

		this.#thumbnailElements = [...this.#rootElement.querySelectorAll('.sauna-list-item__gallery > img')];

		const liElements = splideElement.querySelectorAll('li');
		if (liElements.length > 1) {
			this.#splide = new Splide(splideElement, {
				type: 'loop',
				pagination: false,
				arrows: true,
			});
		} else {
			this.#splide = new Splide(splideElement, {
				type: 'slide',
				drag: false,
				pagination: false,
				arrows: false,
			});
		}



		this.#splide.mount();

		this.#thumbnailElements.forEach((thumbnailElement, index) => {
			thumbnailElement.addEventListener('click', () => {
				this.#splide.go(index+1);
			});
		});
	}
}

export default SaunaListItem;